import Plausible from 'plausible-tracker';
const PlausiblePlugin = (context, inject) => {
    var _a;
    const optionsDomain = '';
    const optionsHashMode = '';
    const optionsTrackLocalhost = '';
    const optionsApiHost = '';
    const optionsEnableAutoPageviews = 'true';
    const optionsEnableAutoOutboundTracking = 'false';
    let options = Object.assign({ hashMode: optionsHashMode === 'true', trackLocalhost: optionsTrackLocalhost === 'true', apiHost: optionsApiHost.length ? optionsApiHost : 'https://plausible.io', enableAutoPageviews: optionsEnableAutoPageviews === 'true', enableAutoOutboundTracking: optionsEnableAutoOutboundTracking === 'true' }, (_a = context.$config) === null || _a === void 0 ? void 0 : _a.plausible);
    if (optionsDomain.length) {
        options = Object.assign(Object.assign({}, options), { domain: optionsDomain });
    }
    const plausible = Plausible(options);
    if (options.enableAutoPageviews === true) {
        plausible.enableAutoPageviews();
    }
    if (options.enableAutoOutboundTracking === true) {
        plausible.enableAutoOutboundTracking();
    }
    if (inject) {
        // Nuxt 2
        inject('plausible', plausible);
    }
    else {
        // Nuxt 3
        // @ts-ignore
        context.provide('plausible', plausible);
    }
};
export default PlausiblePlugin;
